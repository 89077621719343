import ContactDemo from "../../components/contactDemo"
// Import Images
import GigwellRooamLogos from "../../images/demo-brands/gigwell-rooam-logos.jpg"
// Import Components
import Layout from "../../components/layout"
import React from "react"
// Video Player
import ReactPlayer from "react-player"
import SEO from "../../components/seo"

const Gigwell = () => {
  return (
    <Layout>
      <SEO
        title="Rooam + Gigwell - Two best in class technology companies for live music."
        description="Gigwell presents Rooam's cutting edge mobile web tab experience for our partners and friends to utilize at their venues and events. Integrated into your POS and ticketing platform."
      />
      <div className="top-message text-center background-dark-grey">
        <p className="no-top-bottom small font-weight-400 text-light-grey">
          Accept Apple Pay &amp; Google Pay today!
        </p>
      </div>
      <div className="section no-bottom">
        <div className="container text-center">
          <div className="peak-wrap no-top">
            <div className="icon rooam-toast-logos">
              <img src={GigwellRooamLogos} alt="Gigwell + Rooam Logos" />
            </div>
          </div>
          <p className="text-light-grey no-bottom">
            As a best-in-class technology company for live events, Gigwell
            always appreciates and promotes other companies of similar ilk to
            our partners and friends. Rooam has created the first mobile web tab
            experience that's seamlessly integrated into existing POS systems
            and ticketing platforms. Guest can open and close a tab from their
            phones which improves the speed of service and increases bar sales
            by 30% for operators.
            <br />
            <br />
            Rooam will be offering a free trial through the end of 2021. Just
            fill out the demo request below.
          </p>
        </div>
      </div>

      <div className="section no-top-bottom">
        <ContactDemo formName="request-demo-gigwell" />
      </div>

      <div className="section">
        <div className="container">
          <div className="player-wrapper video-rounded video-border no-select">
            <ReactPlayer
              url="https://media.rooam.co/video/b2b/Rooam-WebTab-QR.mp4"
              className="react-player video-rounded"
              playing
              width="100%"
              height="100%"
              muted={true}
              controls={true}
              light="../../videos/posters/Rooam-WebTab.jpg"
              playIcon={
                <div className="play-btn">
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-icon"
                      viewBox="0 0 24 24"
                    >
                      <path d="M3 22v-20l18 10-18 10z" />
                    </svg>
                  </span>
                </div>
              }
            />
          </div>
        </div>
        <div className="container">
          <p className="small text-grey text-center">
            Watch an overview of how Rooam works
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default Gigwell
